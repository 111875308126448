// 使用示例
import { request } from "@/utils/request"
// api模块
import chat from "./chat"
import write from "./write"
import common from "./common"
import drawing from "./drawing"
import login from "./login"
import recharge from "./recharge"
import role from "./role"

const apiList = {
	// 公共
	common,
	// 登录
	login,
	// 案例
	case: {
		classify: {
			url: "/addons/chatgpt/archives/cotegory",
			method: "GET",
			desc: "分类"
		},
		list: {
			url: "/addons/chatgpt/archives/index",
			method: "GET",
			desc: "列表"
		},
		detail: {
			url: "/addons/chatgpt/archives/show",
			method: "GET",
			desc: "详情"
		}
	},
	ai_write:{
		baocun: {
			url: "/addons/chatgpt/aiwrite/baocun",
			method: "POST",
			desc: "保存"
		},
		getlist: {
			url: "/addons/chatgpt/aiwrite/getlist",
			method: "GET",
			desc: "获取列表"
		},
		takecontentlist:{
			url: "/addons/chatgpt/aiwrite/takecontentlist",
			method: "POST",
			desc: "获取详细内容"
		},
		edit_title:{
			url: "/addons/chatgpt/aiwrite/edit_title",
			method: "POST",
			desc: "修改标题"
		},
		del_word:{
			url: "/addons/chatgpt/aiwrite/del_word",
			method: "POST",
			desc: "删除文件"
		},
	},
	markdown:{
		takemakelist:{
			url: "/addons/chatgpt/markdown/takemakelist",
			method: "POST",
			desc: "获取历史记录"
		},
		getmakelist:{
			url: "/addons/chatgpt/markdown/getmakelist",
			method: "POST",
			desc: "获取历史列表"
		},
	},
	knowledge:{
		get_all_knowledge:{
			url: "/addons/chatgpt/knowledge/show",
			method: "GET",
			desc: "获取知识库"
		},
		get_all_knowledge_open:{
			url: "/addons/chatgpt/knowledge/show_open",
			method: "GET",
			desc: "获取公开知识库"
		},
		send_public:{
			url: "/addons/chatgpt/knowledge/send_public",
			method: "GET",
			desc: "公开知识库"
		},
		send_name:{
			url: "/addons/chatgpt/knowledge/send_name",
			method: "GET",
			desc: "重命名"
		},
		delete_this:{
			url: "/addons/chatgpt/knowledge/delete_this",
			method: "GET",
			desc: "删除知识库"
		},
		creat_know:{
			url: "/addons/chatgpt/knowledge/creat_public",
			method: "GET",
			desc: "创建知识库"
		},
		creat_know_vector:{
			url: "/addons/chatgpt/knowledge/creat_know_vector",
			method: "GET",
			desc: "获取数据集"
		},
		edit_shujuji:{
			url: "/addons/chatgpt/knowledge/edit_shujuji",
			method: "GET",
			desc: "修改数据集"
		},
		delete_this_shujuji:{
			url: "/addons/chatgpt/knowledge/delete_this_shujuji",
			method: "GET",
			desc: "删除数据集"
		}
	},
	song:{
		lyrics:{
			url: "/addons/chatgpt/song/lyrics",
			method: "POST",
			desc: "生成歌词"
		},
		getlyricsResult:{
			url: "/addons/chatgpt/song/getlyricsResult",
			method: "POST",
			desc: "歌词查询"
		},
		create:{
			url: "/addons/chatgpt/song/create",
			method: "POST",
			desc: "生成歌曲"
		},
		getSongResult:{
			url: "/addons/chatgpt/song/getSongResult",
			method: "POST",
			desc: "查询歌曲生成状态"
		},
		getData:{
			url: "/addons/chatgpt/song/getData",
			method: "POST",
			desc: "获取歌曲数据"
		},
		getConfig:{
			url: "/addons/chatgpt/song/getConfig",
			method: "POST",
			desc: "获取歌曲配置"
		}
	},
	video:{

		getData:{
			url: "/addons/chatgpt/video/getData",
			method: "POST",
			desc: "获取视频数据"
		},
		getConfig:{
			url: "/addons/chatgpt/video/getConfig",
			method: "POST",
			desc: "获取视频配置"
		},
		createVideo:{
			url: "/addons/chatgpt/video/createVideo",
			method: "POST",
			desc: "生成视频任务"
		},
		getVideoResult:{
			url: "/addons/chatgpt/video/getVideoResult",
			method: "POST",
			desc: "查询视频生成结果"
		},
		record:{
			url: "/addons/chatgpt/video/userVideo",
			method: "POST",
			desc: "视频列表"
		}

	},
	// 用户
	user: {
		info: {
			url: "/addons/chatgpt/user/index",
			method: "GET",
			desc: "用户信息"
		},
		cdkey: {
			url: "/addons/chatgpt/cryptocard/decrypt_card",
			method: "GET",
			desc: "卡密兑换"
		}
	},
	// 会话
	chat,
	// 创作
	write,
	// 角色
	role,
	// 分销
	place: {
		data: {
			url: "/addons/chatgpt/user/userData",
			method: "GET",
			desc: "个人数据"
		},
		team: {
			url: "/addons/chatgpt/reseller/user",
			method: "GET",
			desc: "团队"
		},
		brokerage: {
			url: "/addons/chatgpt/reseller/log",
			method: "GET",
			desc: "分销记录"
		}
	},
	// 充值
	recharge,
	// 绘图
	drawing
}

// 组装接口路径
const getApiPath = path => {
	let apiArray = path.split("."),
		api = apiList
	apiArray.forEach(v => {
		api = api[v]
	})

	return api
}

function http(path, data, error = true) {
	const api = getApiPath(path)
	!api && Promise.reject("接口未定义, 已阻止此次API请求")
	const method = api.method.toLowerCase()
	if (method != "get" && method != "post" && method != "fetch") Promise.reject(`${method} 请求方式未定义, 已阻止此次API请求`)
	return request[method](api.url, data, api.timeout, error)
}

export { apiList, http }
